export const DBConfig = {
  name: process.env.REACT_APP_INDEX_DB_NAME,
  version: 2,
  objectStoresMeta: [
    {
      store: "offline_images",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "id", keypath: "id", options: { unique: true } },
        { name: "taskId", keypath: "taskId", options: { unique: false } },
      ],
    },
    {
      store: "task_images",
      storeConfig: { keyPath: "id", autoIncrement: true },
      storeSchema: [
        { name: "id", keypath: "id", options: { unique: true } },
        { name: "taskId", keypath: "taskId", options: { unique: false } },
      ],
    },
  ],
};

export default DBConfig;
