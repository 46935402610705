// IMPORTANTE. NO MODIFICAR ESTE ARCHIVO MANUALMENTE

import { merge } from "lodash";

import defaultConfig from "./brands/default/app.config";


const label = process.env.REACT_APP_WHITE_LABEL_APP as "default";


async function config() {
  const appConfig = await import(`./brands/${label}/app.config`);
  return merge({}, defaultConfig, appConfig.default);
}


export default {
  defaultConfig,
  config
}
