import * as app from "./app";

export const CHANGE_THEME_CONFIG = "CHANGE_THEME_CONFIG";
export const CHANGE_APP_TITLE = "CHANGE_APP_TITLE";
export const SET_SESSION_DATA = "SET_SESSION_DATA";
export const NETWORK_STATUS_CHANGE = "NETWORK_STATUS_CHANGE";
export const PEDING_SYNC_STATUS_CHANGE = "PEDING_SYNC_STATUS_CHANGE";
export const SHOW_GENERAL_LOADER = "SHOW_GENERAL_LOADER";
export const DATASTORE_STATUS_CHANGE = "DATASTORE_STATUS_CHANGE";
export const DATASTORE_MODELS_CHANGE = "DATASTORE_MODELS_CHANGE";
export const DATASTORE_MODELS_SYNCED_CHANGE = "DATASTORE_MODELS_SYNCED_CHANGE";
export const DATASTORE_RESET_SYNCED_MODELS = "DATASTORE_RESET_SYNCED_MODELS";
export const ENQUEUE_OPERATION = "ENQUEUE_OPERATION";
export const DEQUEUE_OPERATION = "DEQUEUE_OPERATION";
export const CHANGE_SYSTEM_CONFIG = "CHANGE_SYSTEM_CONFIG";
export const SIGN_IN = "SIGN_IN";
export const SIGN_OUT = "SIGN_OUT";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
export const SITE_GET_USER_TEAM_PENDING = "SITE_GET_USER_TEAM_PENDING";
export const SITE_GET_USER_TEAM_SUCCESS = "SITE_GET_USER_TEAM_SUCCESS";
export const SITE_GET_USER_TEAM_ERROR = "SITE_GET_USER_TEAM_ERROR";
export const SITE_GET_TEAM_SUCCESS = "SITE_GET_TEAM_SUCCESS";
export const SITE_GET_TEAM_ERROR = "SITE_GET_TEAM_ERROR";
export const SITE_DELETE_USER_SUCCESS = "SITE_DELETE_USER_SUCCESS";
export const SITE_DELETE_USER_PENDING = "SITE_DELETE_USER_PENDING";
export const SITE_DELETE_USER_ERROR = "SITE_DELETE_USER_ERROR";
export const SITE_FETCH_USERS_PENDING = "SITE_FETCH_USERS_PENDING";
export const SITE_FETCH_USERS_SUCCESS = "SITE_FETCH_USERS_SUCCESS";
export const SITE_FETCH_USERS_ERROR = "SITE_FETCH_USERS_ERROR";
export const SITE_ASSIGN_USERS_PENDING = "SITE_ASSIGN_USERS_PENDING";
export const SITE_ASSIGN_USERS_SUCCESS = "SITE_ASSIGN_USERS_SUCCESS";
export const SITE_ASSIGN_USERS_ERROR = "SITE_ASSIGN_USERS_ERROR";
export const SITE_RESET_STATE = "SITE_RESET_STATE";
export const ACTIVITY_FETCH_ALL_PENDING = "ACTIVITY_FETCH_ALL_PENDING";
export const ACTIVITY_FETCH_ALL_SUCCESS = "ACTIVITY_FETCH_ALL_SUCCESS";
export const ACTIVITY_FETCH_NEXT_SUCCESS = "ACTIVITY_FETCH_NEXT_SUCCESS";
export const ACTIVITY_FETCH_ALL_ERROR = "ACTIVITY_FETCH_ALL_ERROR";
export const ACTIVITY_UPDATE_ITEM_PENDING = "ACTIVITY_UPDATE_ITEM_PENDING";
export const ACTIVITY_UPDATE_ITEM_SUCCESS = "ACTIVITY_UPDATE_ITEM_SUCCESS";
export const ACTIVITY_UPDATE_ITEM_ERROR = "ACTIVITY_UPDATE_ITEM_ERROR";
export const ACTIVITY_CREATE_PENDING = "ACTIVITY_CREATE_PENDING";
export const ACTIVITY_CREATE_SUCCESS = "ACTIVITY_CREATE_SUCCESS";
export const ACTIVITY_CREATE_ERROR = "ACTIVITY_CREATE_ERROR";
export const ACTIVITY_RESET_STATUS = "ACTIVITY_RESET_STATUSES";
export const USERS_FETCH_ALL_PENDING = "USERS_FETCH_ALL_PENDING";
export const USERS_FETCH_ALL_SUCCESS = "USERS_FETCH_ALL_SUCCESS";
export const USERS_FETCH_NEXT_SUCCESS = "USERS_FETCH_NEXT_SUCCESS";
export const USERS_FETCH_ALL_ERROR = "USERS_FETCH_ALL_ERROR";
export const USERS_FETCH_DETAILS_PENDING = "USERS_FETCH_DETAILS_PENDING";
export const USERS_FETCH_DETAILS_SUCCESS = "USERS_FETCH_DETAILS_SUCCESS";
export const USERS_FETCH_DETAILS_ERROR = "USERS_FETCH_DETAILS_ERROR";
export const USERS_FETCH_ROLES_SUCCESS = "USERS_FETCH_ROLES_SUCCESS";
export const USERS_ASSIGN_ROLE_PENDING = "USERS_ASSIGN_ROLE_PENDING";
export const USERS_ASSIGN_ROLE_SUCCESS = "USERS_ASSIGN_ROLE_SUCCESS";
export const USERS_ASSIGN_ROLE_ERROR = "USERS_ASSIGN_ROLE_ERROR";
export const USERS_REMOVE_ROLE_PENDING = "USERS_REMOVE_ROLE_PENDING";
export const USERS_REMOVE_ROLE_SUCCESS = "USERS_REMOVE_ROLE_SUCCESS";
export const USERS_REMOVE_ROLE_ERROR = "USERS_REMOVE_ROLE_ERROR";
export const USERS_SET_STATUS_PENDING = "USERS_SET_STATUS_PENDING";
export const USERS_SET_STATUS_SUCCESS = "USERS_SET_STATUS_SUCCESS";
export const USERS_SET_STATUS_ERROR = "USERS_SET_STATUS_ERROR";
export const PREMISES_FETCH_ALL_PENDING = "PREMISES_FETCH_ALL_PENDING";
export const PREMISES_FETCH_ALL_SUCCESS = "PREMISES_FETCH_ALL_SUCCESS";
export const PREMISES_FETCH_NEXT_SUCCESS = "PREMISES_FETCH_NEXT_SUCCESS";
export const PREMISES_FETCH_ALL_ERROR = "PREMISES_FETCH_ALL_ERROR";
export const PREMISES_CREATE_BEFORE_PENDING = "PREMISES_CREATE_BEFORE_PENDING";
export const PREMISES_CREATE_BEFORE_SUCCESS = "PREMISES_CREATE_BEFORE_SUCCESS";
export const PREMISES_CREATE_BEFORE_ERROR = "PREMISES_CREATE_BEFORE_ERROR";
export const PREMISES_CREATE_BEFORE_CLEAR = "PREMISES_CREATE_BEFORE_CLEAR";
export const PREMISES_CREATE_PENDING = "PREMISES_CREATE_PENDING";
export const PREMISES_CREATE_SUCCESS = "PREMISES_CREATE_SUCCESS";
export const PREMISES_CREATE_ERROR = "PREMISES_CREATE_ERROR";
export const PREMISES_CREATE_CLEAR = "PREMISES_CREATE_CLEAR";
export const PREMISES_FETCH_DETAILS_PENDING = "PREMISES_FETCH_DETAILS_PENDING";
export const PREMISES_FETCH_DETAILS_SUCCESS = "PREMISES_FETCH_DETAILS_SUCCESS";
export const PREMISES_FETCH_DETAILS_ERROR = "PREMISES_FETCH_DETAILS_ERROR";
export const PREMISES_UPDATE_ITEM_SUCCESS = "PREMISES_UPDATE_ITEM_SUCCESS";
export const PREMISES_UPDATE_ITEM_PENDING = "PREMISES_UPDATE_ITEM_PENDING";
export const PREMISES_UPDATE_ITEM_ERROR = "PREMISES_UPDATE_ITEM_ERROR";
export const BUILDING_LOCATION_CREATE_PENDING = "BUILDING_LOCATION_CREATE_PENDING";
export const BUILDING_LOCATION_CREATE_SUCCESS = "BUILDING_LOCATION_CREATE_SUCCESS";
export const BUILDING_LOCATION_CREATE_ERROR = "BUILDING_LOCATION_CREATE_ERROR";
export const BUILDING_LOCATION_UPDATE_PENDING = "BUILDING_LOCATION_UPDATE_PENDING";
export const BUILDING_LOCATION_UPDATE_SUCCESS = "BUILDING_LOCATION_UPDATE_SUCCESS";
export const BUILDING_LOCATION_UPDATE_ERROR = "BUILDING_LOCATION_UPDATE_ERROR";
export const BUILDING_LOCATION_CREATE_CLEAR = "BUILDING_LOCATION_CREATE_CLEAR";
// MATERIALES INICIO
export const FETCH_MATERIAL_CATALOG_SUCCESS = "FETCH_MATERIAL_CATALOG_SUCCESS";
export const FETCH_NEXT_MATERIAL_CATALOG_SUCCESS = "FETCH_NEXT_MATERIAL_CATALOG_SUCCESS";
export const FETCH_MATERIAL_CATALOG_PENDING = "FETCH_MATERIAL_CATALOG_PENDING";
export const FETCH_MATERIAL_CATALOG_ERROR = "FETCH_MATERIAL_CATALOG_ERROR";

export const SEARCH_MATERIAL_CATALOG_PENDING = "SEARCH_MATERIAL_CATALOG_PENDING";
export const SEARCH_MATERIAL_CATALOG_SUCCESS = "SEARCH_MATERIAL_CATALOG_SUCCESS";
export const SEARCH_NEXT_MATERIAL_CATALOG_SUCCESS = "SEARCH_NEXT_MATERIAL_CATALOG_SUCCESS";
export const SEARCH_MATERIAL_CATALOG_ERROR = "SEARCH_MATERIAL_CATALOG_ERROR";

export const CREATE_MATERIAL_SUCCESS = "CREATE_MATERIAL_SUCCESS";
export const CREATE_MATERIAL_PENDING = "CREATE_MATERIAL_PENDING";
export const CREATE_MATERIAL_ERROR = "CREATE_MATERIAL_ERROR";

export const UPDATE_MATERIAL_SUCCESS = "UPDATE_MATERIAL_SUCCESS";
export const UPDATE_MATERIAL_PENDING = "UPDATE_MATERIAL_PENDING";
export const UPDATE_MATERIAL_ERROR = "UPDATE_MATERIAL_ERROR";

export const CLEAN_MATERIAL_REDUCER = "CLEAN_MATERIAL_REDUCE";

// MATERIALES FIN

// UOM INICIO
export const FETCH_UOM_CATALAOG_SUCCESS = "FETCH_UOM_CATALAOG_SUCCESS";
export const FETCH_NEXT_UOM_CATALOG_SUCCESS = "FETCH_NEXT_UOM_CATALOG_SUCCESS";
export const FETCH_UOM_CATALAOG_PENDING = "FETCH_UOM_CATALAOG_PENDING";
export const FETCH_UOM_CATALAOG_ERROR = "FETCH_UOM_CATALAOG_ERROR";

export const CREATE_UOM_SUCCESS = "CREATE_UOM_SUCCESS";
export const CREATE_UOM_PENDING = "CREATE_UOM_PENDING";
export const CREATE_UOM_ERROR = "CREATE_UOM_ERROR";

export const UPDATE_UOM_SUCCESS = "UPDATE_UOM_SUCCESS";
export const UPDATE_UOM_PENDING = "UPDATE_UOM_PENDING";
export const UPDATE_UOM_ERROR = "UPDATE_UOM_ERROR";

export const CLEAN_UOM_REDUCER = "CLEAN_UOM_REDUCER";

// UOM FIN

export const FETCH_MATERIAL_CATEGORY_CATALAOG_SUCCESS = "FETCH_MATERIAL_CATEGORY_CATALAOG_SUCCESS";
export const FETCH_MATERIAL_CATEGORY_CATALAOG_PENDING = "FETCH_MATERIAL_CATEGORY_CATALAOG_PENDING";
export const FETCH_MATERIAL_CATEGORY_CATALAOG_ERROR = "FETCH_MATERIAL_CATEGORY_CATALAOG_ERROR";

// useReducer INICIO
export const SET_ALL_INSTALLATION_MATERIAL = "SET_ALL_INSTALLATION_MATERIALS";
export const SET_ONLY_ALL_MATERIAL = "SET_ONLY_ALL_MATERIAL";
export const FORMAT_MATERIAL_ADDED = "FORMAT_MATERIAL_ADDED";
export const SET_KIT_MATERIALS = "SET_KIT_MATERIALS";
export const SELECT_MATERIALS_ITEMS = "SELECT_MATERIALS_ITEMS";
export const REPLACE_ALL_INSTALLATION_MATERIAL = "REPLACE_ALL_INSTALLATION_MATERIAL";
export const ADD_INSTALLATION_MATERIAL = "ADD_INSTALLATION_MATERIAL";
export const UPDATE_INSTALLATION_MATERIAL = "UPDATE_INSTALLATION_MATERIAL";
export const DELETE_INSTALLATION_MATERIAL = "DELETE_INSTALLATION_MATERIAL";
export const RESET_MATERIALS = "RESET_MATERIALS";
// useReducer FIN

// Service Worker FIN
export const SERVICE_WORKER_UPDATE = "SERVICE_WORKER_UPDATE";
export const SERVICE_WORKER_UPDATE_PENDING = "SERVICE_WORKER_UPDATE_PENDING";
export const SERVICE_WORKER_ACTIVE = "SERVICE_WORKER_ACTIVE";
export const SERVICE_WORKER_CLEAN = "SERVICE_WORKER_CLEAN";
// Service Worker FIN

// Code Reader INICIO
export const START_BARCODE_SCANNER = "START_BARCODE_SCANNER";
export const STOP_BARCODE_SCANNER = "STOP_BARCODE_SCANNER";
export const SET_CONTENT_BARCODE_SCANNER = "SET_CONTENT_BARCODE_SCANNER";
export const SET_CONTAINER_BARCODE_SCANNER = "SET_CONTAINER_BARCODE_SCANNER";
// Code Reader FIN

// useDeviceInfo Hook: INICIO
export const SET_DEVICE_INFO = "SET_DEVICE_INFO";
export const RESET_DEVICE_INFO = "RESET_DEVICE_INFO";
// useDeviceInfo Hook: FIN

export default {
  SHOW_GENERAL_LOADER,
  CHANGE_THEME_CONFIG,
  CHANGE_APP_TITLE,
  SET_SESSION_DATA,
  NETWORK_STATUS_CHANGE,
  PEDING_SYNC_STATUS_CHANGE,
  DATASTORE_STATUS_CHANGE,
  DATASTORE_MODELS_CHANGE,
  DATASTORE_MODELS_SYNCED_CHANGE,
  DATASTORE_RESET_SYNCED_MODELS,
  ENQUEUE_OPERATION,
  DEQUEUE_OPERATION,
  CHANGE_SYSTEM_CONFIG,
  SIGN_OUT,
  SIGN_IN,
  SHOW_NOTIFICATION,
  REMOVE_NOTIFICATION,
  SITE_GET_TEAM_SUCCESS,
  SITE_GET_TEAM_ERROR,
  SITE_GET_USER_TEAM_PENDING,
  SITE_GET_USER_TEAM_SUCCESS,
  SITE_GET_USER_TEAM_ERROR,
  SITE_DELETE_USER_SUCCESS,
  SITE_FETCH_USERS_PENDING,
  SITE_FETCH_USERS_SUCCESS,
  SITE_FETCH_USERS_ERROR,
  SITE_ASSIGN_USERS_SUCCESS,
  ACTIVITY_FETCH_ALL_PENDING,
  ACTIVITY_FETCH_ALL_SUCCESS,
  ACTIVITY_FETCH_ALL_ERROR,
  ACTIVITY_UPDATE_ITEM_SUCCESS,
  ACTIVITY_CREATE_SUCCESS,
  USERS_FETCH_ALL_PENDING,
  USERS_FETCH_ALL_SUCCESS,
  USERS_FETCH_ALL_ERROR,
  USERS_FETCH_DETAILS_PENDING,
  USERS_FETCH_DETAILS_SUCCESS,
  USERS_FETCH_DETAILS_ERROR,
  USERS_FETCH_ROLES_SUCCESS,
  USERS_ASSIGN_ROLE_PENDING,
  USERS_ASSIGN_ROLE_SUCCESS,
  USERS_ASSIGN_ROLE_ERROR,
  USERS_REMOVE_ROLE_PENDING,
  USERS_REMOVE_ROLE_SUCCESS,
  USERS_REMOVE_ROLE_ERROR,
  app,
};
