import { useState, useCallback, useMemo } from "react";

function useLoadingStatus<T>(promise: (...args: any[]) => Promise<T>) {
  const [loading, setLoading] = useState<boolean>(false);

  const task = useCallback(
    (...args: any[]): Promise<any> => {
      setLoading(true);
      return promise(...args).then(
        (result: any) => {
          setLoading(false);
          return result;
        },
        (error: Error) => {
          setLoading(false);
          throw error;
        }
      );
    },
    [promise]
  );

  return useMemo(
    () => [loading, task], 
    [loading, task]
  );
}

export default useLoadingStatus;
