import React from "react";
import PropTypes from "prop-types";

import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";

import Button from "components/custom/Button";

function ConfirmationDialog(props) {
  const {
    onCancel,
    onConfirm,
    okDisabled,
    cancelDisabled,
    title,
    open,
    okBtnText,
    cancelBtnText,
    loading,
    showOkBtn,
    children,
    ...other
  } = props;

  const handleCancel = () => {
    onCancel();
  };

  const handleOk = (e) => {
    onConfirm(e);
  };

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      maxWidth="xs"
      aria-labelledby="confirmation-dialog-title"
      open={open}
      {...other}
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent dividers>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} disabled={cancelDisabled || loading} color="default">
          {cancelBtnText}
        </Button>
        {showOkBtn && (
          <Button onClick={handleOk} disabled={okDisabled || loading} isLoading={loading} color="primary">
            {okBtnText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  okBtnText: PropTypes.string,
  cancelBtnText: PropTypes.string,
  okDisabled: PropTypes.bool,
  cancelDisabled: PropTypes.bool,
  showOkBtn: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

ConfirmationDialog.defaultProps = {
  title: "",
  onCancel: () => {},
  onConfirm: () => {},
  open: false,
  loading: false,
  cancelBtnText: "Cancelar",
  okBtnText: "Continuar",
  okDisabled: false,
  cancelDisabled: false,
  showOkBtn: true,
  children: null,
};

export const MessageDialog = ({ children, ...props }) => <ConfirmationDialog {...props}>{children}</ConfirmationDialog>;

MessageDialog.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
};

export default ConfirmationDialog;
