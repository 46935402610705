import React from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Zoom from "@material-ui/core/Zoom";

import makeStyles from "@material-ui/core/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  // Progress styles
  buttonProgress: {
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    // margin: theme.spacing(1),
    position: "relative",
    display: "inline",
    // width: '100%'
  },
}));

const transitionDuration = {
  enter: 200,
  exit: 200,
};
function CustomButton({ isLoading, loading, disabled, visible, animated, ...props }) {
  const classes = useStyles();
  const WrappedButton = (
    <div className={classes.wrapper}>
      <Button {...props} disabled={disabled || isLoading || loading} />
      {(isLoading || loading) && <CircularProgress size={24} className={classes.buttonProgress} />}
    </div>
  );

  return animated ? (
    <Zoom
      in={visible}
      timeout={transitionDuration}
      style={{
        transitionDelay: `${visible ? transitionDuration.exit : 0}ms`,
      }}
      unmountOnExit
    >
      {WrappedButton}
    </Zoom>
  ) : (
    visible && WrappedButton
  );
}

CustomButton.defaultProps = {
  isLoading: false,
  loading: false,
  visible: true,
  animated: false,
  disabled: false,
};

CustomButton.propTypes = {
  // Activa efecto de carga en boton (version legada, usar loading en su lugar)
  isLoading: PropTypes.bool,
  // Activa efecto de carga en boton
  loading: PropTypes.bool,
  // Controla la visibilidad del boton
  visible: PropTypes.bool,
  // Agrega animacion en aparicion y desaparicion del boton
  animated: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CustomButton;
