import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import JssProvider from "react-jss/lib/JssProvider";
import { ThemeProvider } from "@material-ui/core/styles";
import responsiveFontSizes from "@material-ui/core/styles/responsiveFontSizes";
import { createMuiTheme } from "@material-ui/core/styles";
import jss from "react-jss/lib/jss";
import DayJsUtils from "@date-io/dayjs";
import createGenerateClassName from "@material-ui/styles/createGenerateClassName";

import { setSessionData } from "redux/dispatcher/session";
import { setSystemConfigurations as _setSystemConfigurations } from "redux/dispatcher/configurations";
import { setTheme as _setTheme } from "redux/dispatcher/theme";
import * as AUTH_STATE from "constant/auth/state";

import appConfig from "app.config";

const ApplicationContext = React.createContext({});

const identitySelector = (store) => store;

const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: false,
  productionPrefix: "c",
});

function getTheme(theme) {
  return responsiveFontSizes(
    createMuiTheme({
      palette: {
        primary: {
          main: theme.primaryColor,
        },
        error: {
          main: "#E02064",
        },
        background: {
          default: "rgba(0, 0, 0, 0)",
        },
      },
      overrides: {
        MuiCssBaseline: {
          "@global": {
            ":root": {
              "--amplify-primary-color": theme.primaryColor,
              "--amplify-primary-tint": theme.amplifyPrimaryTint,
              "--amplify-primary-shade": theme.amplifyPrimaryShade,
            },
          },
        },
      },
    })
  );
}

function ApplicationContextProvider({ children }) {
  const [state, setState] = useState(appConfig.defaultConfig);
  const dispatcher = useDispatch();
  const reduxStore = useSelector(identitySelector);

  useEffect(() => {
    checkAppConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxStore]);

  async function checkAppConfig() {
    const config = await appConfig.config();

    if (reduxStore?.session?.uuid !== config?.session?.uuid) {
      let newSessionData = { uuid: config?.session?.uuid };

      if (reduxStore?.session?.state !== AUTH_STATE.SIGN_IN) {
        setState({ ...config });
        newSessionData = { ...config.session };
      }

      setSession(newSessionData);
      setTheme({ ...config.theme });
    } else {
      const _state = {
        ...reduxStore,
      };
      _state.theme = reduxStore.theme;
      setState(_state);
    }
  }

  function setSession(session) {
    setState((currentValue) => ({
      ...currentValue,
      session: {
        ...currentValue.session,
        ...session,
      },
    }));
    dispatcher(setSessionData(session));
  }

  function clearSession() {
    setState((currentValue) => ({
      ...currentValue,
      session: {
        ...appConfig.defaultConfig.session,
      },
    }));
    dispatcher(setSessionData(appConfig.defaultConfig.session));
  }

  function setSystemConfigurations(systemConfigs) {
    setState((currentValue) => ({
      ...currentValue,
      systemConfigs: {
        ...currentValue.systemConfigs,
        ...systemConfigs,
      },
    }));
    dispatcher(_setSystemConfigurations(systemConfigs));
  }

  function setTheme(theme) {
    setState((currentValue) => ({
      ...currentValue,
      theme: {
        ...currentValue.theme,
        ...theme,
      },
    }));
    dispatcher(_setTheme(theme));
  }

  const _muiTheme = useMemo(() => getTheme(state.theme), [state.theme]);

  return (
    <ThemeProvider theme={_muiTheme}>
      <JssProvider jss={jss} generateClassName={generateClassName}>
        <MuiPickersUtilsProvider utils={DayJsUtils}>
          <ApplicationContext.Provider
            value={{
              ...state,
              setSession,
              clearSession,
              setSystemConfigurations,
              setTheme,
            }}
          >
            {children}
          </ApplicationContext.Provider>
        </MuiPickersUtilsProvider>
      </JssProvider>
    </ThemeProvider>
  );
}

export default {
  Context: ApplicationContext,
  Provider: ApplicationContextProvider,
};
